/* html{  
    margin: 0;
    padding:0;
    width:100%  
  } 
*/
.list-transition-enter, .list-transition-appear {
    /*We give the list the initial dimension of the list button*/
    top: 0;
    width: 120px;
    max-height: 40px;
    color: transparent;
    background-color: #5a564c;
    }
    /* This is where we can add the transition*/
    .list-transition-enter-active, .list-transition-appear-active {
    top: 45px;
    width: 200px;
    max-height: 200px;
    background-color: #9e8949;
    transition: all 400ms;
    }




.wrapper{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
     
    
  }
  
  /*#CCD6F6   gray*/
  /*#64FFDA  green
  #0B1A2F
  */
  
  /* #text1,#text1, #main */
  
  
  /*
  [#text2] has length of 1704.3990478515625
  
  [#text1] has length of 3100.80908203125
  
  [#main filter] has length of 1722.098876953125
  
  [main(lastchild)] has length of 3572.283935546875
  
  */ 
  
  #text2 , #text1 path {
    stroke: #CCD6F6;
    fill:none;
    stroke-dasharray: 3710;
    stroke-dashoffset: 3710;
    opacity: 10;
    animation: text-anim2 5s forwards;
     
  }  
  
  @keyframes text-anim2{
  
   
    0% {
      opacity : 0;
      fill:none;
      stroke-dashoffset:3710;
    }
    
    30%{
      opacity : 10;
      fill:none;
      stroke-dashoffset:3710;
    }
    
   
    
    90%{
      opacity: 10;
      fill: #CCD6F6;
      stroke-dashoffset: 0;
    }
  }
  
  
  
  
  
  #mainJ path{
  
    opacity:10;
    z-index: 2;
    animation: mainJ-anim 6.5s ease-in forwards 
  
  }   
  
  @keyframes mainJ-anim{
    
     0% {
      opacity: 0;
    }
    30%{
      opacity:0;
    }
     50%{
      opacity:0;
    }
    
     70%{
     opacity:10;
    }
   80% {
      opacity: 10;
    }
    100% {
      opacity: 0;
    }
    /*
    from{
      opacity: 10;
    }
    to{
      opacity:0;
    }*/
    
    
    
  }
  
  