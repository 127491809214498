
@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&amp;display=swap");

@font-face {
    font-family: 'Calibre-bold';
    src: url("../../fonts/Calibre/Calibre-Semibold.woff2") format('woff2'), url("../../fonts/Calibre/Calibre-Semibold.woff") format('woff'),
      url("../../fonts/Calibre/Calibre-Semibold.ttf") format('truetype');
    font-weight: "600";
    font-style: normal;
  }

.container1{
    position: relative;
    left: 10%;
    top: 150%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    max-height: 90%;

}

.pageNumber{
    
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 20px;
    position: relative;
    bottom: 4px;
}

/* 370 to 935 */

@media (max-width: 480px) { 
    
    .nameanim{
        font-size: 18px !important;
        line-height: 40px !important;
    }

    .j-name{
        width: 0.9em !important;
        margin-top: -9em !important;
        margin-left: 1.43em !important;
    }
 }

.j-name{
    width: 3.4em;     
        margin-top: -18em;
        /* margin-right: -2em; */
        margin-left: 6em;
}
 
@media (min-width: 935px) { 
    
    .nameanim::before{
        left: 2% !important;
    }

   
 }

 /* 935 - 545 */

@media (min-width: 480px) and (max-width: 935px) { 
    
    .nameanim{
        font-size: xx-large !important;
        line-height: 40px !important;
        margin:30% 0 0% 8% !important;
    }

    .nameanim::before{
        left: 2% !important;
    }


    .nameanim::after{
        margin-top: 10% !important;
    margin-left: -3% !important;
    }


    .j-name{
        width: 5.1% !important;
    margin-top: -30%!important;
    margin-left: 17% !important;

    }

 }

 @media (min-width: 929px)and (max-width:935px){
    .j-name {
        width: 5.1% !important;
        margin-top: -27%!important;
        margin-left: 15% !important;
    }
    
 }





 @media (min-width: 602px)and (max-width:802px){
    .j-name {
        width: 4.1% !important;
        margin-top: -30%!important;
        margin-left: 18% !important;
    
 }
 }

 @media (min-width: 867px)and (max-width:927px){
    .j-name{

        width: 5.1% !important;
        margin-top: -28%!important;
        margin-left: 15% !important;
    }
}
 





 @media (min-width: 480px)and (max-width:511px){
     .j-name{

         width: 6.1% !important;
         margin-top: -40%!important;
         margin-left: 21% !important;
     }
 }

 @media (min-width: 512px)and (max-width:560px){

     .j-name {
        width: 6.1% !important;
        margin-top: -35%!important;
        margin-left: 18% !important;
    }
 }
 @media( min-width:579px) and (max-width: 660px){

     .j-name {
        width: 6.1% !important;
        margin-top: -38%!important;
        margin-left: 20% !important;
 }
 }



 @media( min-width:936px) and (max-width: 1064px){
    .nameanim{
        font-size:3.5em !important
    }
        .kz-photo {
       top: -52% !important;
       right: -110%;
   }
   .j-name {
    width: 3.1em;
    margin-top: -16em;
    margin-left: 4.7em;
 }
 }

 



.nameanim {
    color: #CCD5F6;
    font-size: 4.2em;
    /* line-height: 0.98em; */
    margin: 0; 
    /* font-family: font-file-82132; */
    font-family: "Calibre-bold", font-file-82132;
    /* font-weight: 400; */
    width: 100%;
    margin-top: 1.8em;
  }
  
  .nameanim::before {
    content: "<h1>";
    font-family: "La Belle Aurore",'SF Mono', cursive;
    color:#8891B0;
    font-size: 18px;
    position: absolute;
    margin-top: -43px;
    /* left: -15px; */
    left: 3%;
  }



  .smallText{
      text-align: justify;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 1.15em;
    color: #8891B0;
  }

 
  @media (max-width: 936px ){
    .smallText{

        font-size: 90% !important;
    }
    
  }

  @media (max-width: 936px ) and (min-width:616px ){
    .smallText{

        font-size: 125% !important;
    }
    
  }



  .smallTextLink{
      color:#64FFDA ;
     
  }

  .smallTextLink:hover{
    color:#64FFDA !important;
    text-decoration: underline !important;
}


  .nameanim::after{

    content: '<h1/>';
    font-family: 'La Belle Aurore','SF Mono', cursive;
    color: #8891B0;
    font-size: 18px;
    position: absolute;
    margin-top: 18px;
    margin-left: 20px;

  }
  
  .buttonD {
    /* padding: 15px 60px;
    background: #0079ff;
    color: #fff;
    border-radius: 5px;  
    text-decoration: none;*/
    cursor: pointer;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    transition: all 0.3s ease-out;
  }
  
  .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
  }
  
  .buttonD:hover {
    animation-name: rubberBand;
    display: inline-block;
    color: #64FFDA;
  }
  
  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  
  .angry-animate {
    -webkit-animation: bounce-in 1s ease-in 0s 1 normal;
    -moz-animation: bounce-in 1s ease-in 0s 1 normal;
    -ms-animation: bounce-in 1s ease-in 0s 1 normal;
    animation: bounce-in 1s ease-in 0s 1 normal;
    display: inline-block;
  }
  
  @-webkit-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    70% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Not implemented
  animation-delay: 2s
  */
  
  .bounceIn {
    display: inline-block;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
  }
  
  @keyframes bounceIn {
    0%,
    100%,
    20%,
    40%,
    60%,
    80% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  