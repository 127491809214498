@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://cdn.linearicons.com/free/1.0.0/icon-font.min.css);
@import url(https://fonts.googleapis.com/css2?family=La+Belle+Aurore&amp;display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Homemade+Apple&display=swap);
@import url(https://fonts.googleapis.com/css2?family=La+Belle+Aurore&amp;display=swap);
@import url(https://code.iconify.design/1/1.0.6/iconify.min.js);
@font-face {
  font-family: 'Calibre';
  src: url(/static/media/Calibre-Regular.b63c62e5.woff2) format('woff2'), url(/static/media/Calibre-Regular.e0823214.woff) format('woff'),
    url(/static/media/Calibre-Regular.96e0a7c4.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Mono';
  src: url(/static/media/SFMono-Medium.99497ab6.woff2) format('woff2'), url(/static/media/SFMono-Regular.d59c1702.woff) format('woff'),
    url(/static/media/SFMono-Regular.d892da9f.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}



.Calibre{
  font-family: 'Calibre', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'Segoe UI', Arial, 'sans-serif' !important;
}


.SFMono{
      font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', ' Lucida' ,'Console', 'Monaco', 'monospace' !important;
  }

body {
  margin: 0;
  font-family: 'Calibre', 'Nunito' , -apple-system, BlinkMacSystemFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:fuchsia;
  background: #0A192F !important;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tagUp{
  font-family:  'Cedarville Cursive','Homemade Apple',  "La Belle Aurore", 'SF Mono', cursive ;
    color:#8891B0;
    font-size: 15px;
    position: absolute;
    margin-top: -2em;
    left: -15px;
}

.tagDown{
  font-family:'Cedarville Cursive','Homemade Apple', "La Belle Aurore", 'SF Mono', cursive ;
    color:#8891B0;
    font-size: 15px;
    position: absolute;
    margin-top: 5%;
    left: -15px;
}

.sideSocial{
  /* width: 40px; */
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 6%;
    z-index: 10000;
    color: rgb(168, 178, 209);
}

.socialContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 0.2em;
}

.socialContainer::after{
  content: "";
  display: block;
  width: 0.06em;
  height: 8em;
  background-color: rgb(168, 178, 209);
  /* margin: 0px auto; */
  margin:0.6em 0 0 0;
}

a.socialLinks{
  color: #CCD6F6!important;
  /* padding-left: 0.7em !important; */
  transition: color 0.3s ease-out 0s;
  display:block;
  font-size: 1.5em;
  /* margin-bottom: 0.35em; */
  /* color: #64FFDA!important; */
  line-height: 2.1em;
}

a.socialLinks:hover{

   color: #64FFDA!important;
   -webkit-transform: scale(1.12);
           transform: scale(1.12);
}

.myButton{
  font-family:  "Nunito", "SF Mono";
  width: 9.5em !important;
  /* height: auto; */
  border-color: #64FFDA !important;
  color: #64FFDA !important;
  height: 3em !important;
  background: transparent !important;

}
.myButton:hover{
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
 
  /* height: auto; */
  /* border-color: #63FCD8 !important;
  color: #64FFDA !important;
  height: 3em !important; */
  background: rgba(100, 255, 219, 0.105) !important;

}

@media (min-width: 370px) and (max-width: 935px) { 
    
  .mainDiv{
      margin: 20% 8% 0 3% !important;
  }

}

@media (max-width: 769px){
  .tagUp{
    margin-top: 4% !important;
  }
}

@media (min-width: 769px ){
  .tagUp{
    margin-top: 1% !important;
    left: -1% !important;
  }
}


.mainDiv{
  margin: 3em 0 3em 3em;
}

@media(max-width: 600px){
  .sideSocial{
    left: -80% !important;
  }

  .socialContainer {
   
    margin-bottom: -9% !important;
  }
}



.mainstyle{
    background-image: linear-gradient(to right bottom, #337a85, #2b5d62, #224242, #182827, #090f0e);
  }
  
  .sidenavStyle{
    /* background: rgb(10, 14, 14); */
    /* background: #181818; */
    /* background: #172A45; */
    background: #01050C;
    
  }
  
  @media (min-width: 600px){
    .sideNavCol {
      
      max-width: 5.3em !important;
  }
}
  


@media (max-width: 450px){
  .sideNavCol {
    
    min-width: 5.3em !important;
}
}

.home-icon{
  font-family: 'icomoon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.home-icon::before{
  content:"\e904"
}


  
  /* Nav styles #272727 */
  .navbutton{
    width: 95%;
    transition-duration: 0.5s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    /* border: 1px solid blue; */
    background: #1d2227;
    border: 1px solid white;
    border-radius: 8em;
    color: white;
    /* transition: 0.3s; */
    
  
  }
  .navbutton:hover{
    background: white;
    border: 1px solid #1d2227;
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
    color: #1d2227;
  
  }
  
  .topNavStyle{
    color: rgb(177, 177, 177) !important;
    text-transform: uppercase;
    font-size: x-small;
    font-weight: bolder;
  }
  

  
   .navlistStyle{
    line-height: 3.5em;
    text-align: center;
    display:block;
    cursor: pointer;
  } 
  
  .navlinkStyle{
    color: #4d4d4e !important;
    /* padding-left: 0.7em !important; */
    transition: color 0.5s ease-out 0s;
    display:block;
    font-size: 1.6em;
  }

  /* .lnr {
    color: #4d4d4e !important;
    font-size: 1.6em;
  } */
  /* 
    .lnr:hover{
      opacity: 0;
    } */


   /* .navlinkStyle:active    {
    color: #64FFDA!important;
   
    /* transform: scale(1.07); }
   
    .navlistStyle:hover a i   {
      display: none;
      /* transform: scale(1.07);} */
     
      .navlistStyle:hover a i   {
        display: none;
      }
     
    .navlistStyle:hover a::after   {
      
      transition: color 0.3s ease-out 0s;
      color: #64FFDA!important;
      font-size: 0.5em;
      letter-spacing: 2px;
      text-align: center;
    }


     .sideBarDiv  > li:nth-child(1):hover > a::after {
      content: "Home";
    }
    .sideBarDiv  > li:nth-child(2):hover > a::after {
      content: "Skills";
    }

    .sideBarDiv  > li:nth-child(3):hover > a::after {
      content: "Portfolio";
    }

    .sideBarDiv  > li:nth-child(4):hover > a::after {
      content: "Contact";
    }
  
  /* .navlinkStyle:hover i {
    display: none;
    /* transform: scale(1.07); */
    /*
  } 
 
 

  .navlinkStyle1:hover span  {
    display: block;
    /* transform: scale(1.07); */
   /*
  }

  .navlinkStyle b{
    display: none  !important;
  }

  */

  /* .navlinkStyle1::after{
    content: "portfolio";
    opacity: 1;

  } */
 

   /* .navlinkStyle:hover, .lnr::after{
    opacity: 1;
    content: 'Home';
   } */







  .chosen {
    color: #64FFDA!important;
    transition: color 0.5s ease-out 0s;
    display:block;
  }

  /* .navlinkStyle:hover,.navlinkStyle:active {
    color: #64FFDA!important;
    transform: scale(1.07);
    border-left: .10em solid  green !important;
    padding-left: 0.7em !important;
  } */
   
  /* .chosen {
    color: #64FFDA!important;
    border-left: .10em solid  green !important;
    
  
  } */

  


  
  /* .navlinkStyle:hover::after{
    color: white !important;
    transform: scale(1.07);
    content: ""!important;
    display: block!important;
    margin: 0 auto!important;
    padding-left: 0.5em!important;
    width: 75%!important;
    border-left: .10em solid  green !important;
  } */
  
  .topNavStyle:hover{
    color: white !important;
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
  }
  
  .topNavStyle:hover:after{
   
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 0.5em;
    width: 55%;
    border-bottom: .11em solid  rgb(0, 255, 0) !important;
   
  }
  
  
  /* #alertlist li.selected:after, #alertlist li:hover:after */
  .ajbjbjwbhbj:after{
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid rgb(47, 165, 53); /* This creates the border. Replace black with whatever color you want. */
  }
  
  
  
  
  a, a:hover{
    text-decoration: none !important;
  }
  
  .iconStyle{
    font-size: xx-large;
  }
  
  /* // Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) { 
    .navbutton{
      display:none
  
    }
  
    .iconStyle{
      font-size: xx-small !important;
    }
  }
  
  /* // Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) and (max-width: 767.98px) {
    .iconStyle{
      font-size: xx-small !important;
    } 
  } 
/* html{  
    margin: 0;
    padding:0;
    width:100%  
  } 
*/
.list-transition-enter, .list-transition-appear {
    /*We give the list the initial dimension of the list button*/
    top: 0;
    width: 120px;
    max-height: 40px;
    color: transparent;
    background-color: #5a564c;
    }
    /* This is where we can add the transition*/
    .list-transition-enter-active, .list-transition-appear-active {
    top: 45px;
    width: 200px;
    max-height: 200px;
    background-color: #9e8949;
    transition: all 400ms;
    }




.wrapper{
    position: absolute;
    top:50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
     
    
  }
  
  /*#CCD6F6   gray*/
  /*#64FFDA  green
  #0B1A2F
  */
  
  /* #text1,#text1, #main */
  
  
  /*
  [#text2] has length of 1704.3990478515625
  
  [#text1] has length of 3100.80908203125
  
  [#main filter] has length of 1722.098876953125
  
  [main(lastchild)] has length of 3572.283935546875
  
  */ 
  
  #text2 , #text1 path {
    stroke: #CCD6F6;
    fill:none;
    stroke-dasharray: 3710;
    stroke-dashoffset: 3710;
    opacity: 10;
    -webkit-animation: text-anim2 5s forwards;
            animation: text-anim2 5s forwards;
     
  }  
  
  @-webkit-keyframes text-anim2{
  
   
    0% {
      opacity : 0;
      fill:none;
      stroke-dashoffset:3710;
    }
    
    30%{
      opacity : 10;
      fill:none;
      stroke-dashoffset:3710;
    }
    
   
    
    90%{
      opacity: 10;
      fill: #CCD6F6;
      stroke-dashoffset: 0;
    }
  }  
  
  @keyframes text-anim2{
  
   
    0% {
      opacity : 0;
      fill:none;
      stroke-dashoffset:3710;
    }
    
    30%{
      opacity : 10;
      fill:none;
      stroke-dashoffset:3710;
    }
    
   
    
    90%{
      opacity: 10;
      fill: #CCD6F6;
      stroke-dashoffset: 0;
    }
  }
  
  
  
  
  
  #mainJ path{
  
    opacity:10;
    z-index: 2;
    -webkit-animation: mainJ-anim 6.5s ease-in forwards;
            animation: mainJ-anim 6.5s ease-in forwards 
  
  }   
  
  @-webkit-keyframes mainJ-anim{
    
     0% {
      opacity: 0;
    }
    30%{
      opacity:0;
    }
     50%{
      opacity:0;
    }
    
     70%{
     opacity:10;
    }
   80% {
      opacity: 10;
    }
    100% {
      opacity: 0;
    }
    /*
    from{
      opacity: 10;
    }
    to{
      opacity:0;
    }*/
    
    
    
  }   
  
  @keyframes mainJ-anim{
    
     0% {
      opacity: 0;
    }
    30%{
      opacity:0;
    }
     50%{
      opacity:0;
    }
    
     70%{
     opacity:10;
    }
   80% {
      opacity: 10;
    }
    100% {
      opacity: 0;
    }
    /*
    from{
      opacity: 10;
    }
    to{
      opacity:0;
    }*/
    
    
    
  }
  
  

@font-face {
    font-family: 'Calibre-bold';
    src: url(/static/media/Calibre-Semibold.d8b85647.woff2) format('woff2'), url(/static/media/Calibre-Semibold.34780840.woff) format('woff'),
      url(/static/media/Calibre-Semibold.8325d174.ttf) format('truetype');
    font-weight: "600";
    font-style: normal;
  }

.container1{
    position: relative;
    left: 10%;
    top: 150%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    max-height: 90%;

}

.pageNumber{
    
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 20px;
    position: relative;
    bottom: 4px;
}

/* 370 to 935 */

@media (max-width: 480px) { 
    
    .nameanim{
        font-size: 18px !important;
        line-height: 40px !important;
    }

    .j-name{
        width: 0.9em !important;
        margin-top: -9em !important;
        margin-left: 1.43em !important;
    }
 }

.j-name{
    width: 3.4em;     
        margin-top: -18em;
        /* margin-right: -2em; */
        margin-left: 6em;
}
 
@media (min-width: 935px) { 
    
    .nameanim::before{
        left: 2% !important;
    }

   
 }

 /* 935 - 545 */

@media (min-width: 480px) and (max-width: 935px) { 
    
    .nameanim{
        font-size: xx-large !important;
        line-height: 40px !important;
        margin:30% 0 0% 8% !important;
    }

    .nameanim::before{
        left: 2% !important;
    }


    .nameanim::after{
        margin-top: 10% !important;
    margin-left: -3% !important;
    }


    .j-name{
        width: 5.1% !important;
    margin-top: -30%!important;
    margin-left: 17% !important;

    }

 }

 @media (min-width: 929px)and (max-width:935px){
    .j-name {
        width: 5.1% !important;
        margin-top: -27%!important;
        margin-left: 15% !important;
    }
    
 }





 @media (min-width: 602px)and (max-width:802px){
    .j-name {
        width: 4.1% !important;
        margin-top: -30%!important;
        margin-left: 18% !important;
    
 }
 }

 @media (min-width: 867px)and (max-width:927px){
    .j-name{

        width: 5.1% !important;
        margin-top: -28%!important;
        margin-left: 15% !important;
    }
}
 





 @media (min-width: 480px)and (max-width:511px){
     .j-name{

         width: 6.1% !important;
         margin-top: -40%!important;
         margin-left: 21% !important;
     }
 }

 @media (min-width: 512px)and (max-width:560px){

     .j-name {
        width: 6.1% !important;
        margin-top: -35%!important;
        margin-left: 18% !important;
    }
 }
 @media( min-width:579px) and (max-width: 660px){

     .j-name {
        width: 6.1% !important;
        margin-top: -38%!important;
        margin-left: 20% !important;
 }
 }



 @media( min-width:936px) and (max-width: 1064px){
    .nameanim{
        font-size:3.5em !important
    }
        .kz-photo {
       top: -52% !important;
       right: -110%;
   }
   .j-name {
    width: 3.1em;
    margin-top: -16em;
    margin-left: 4.7em;
 }
 }

 



.nameanim {
    color: #CCD5F6;
    font-size: 4.2em;
    /* line-height: 0.98em; */
    margin: 0; 
    /* font-family: font-file-82132; */
    font-family: "Calibre-bold", font-file-82132;
    /* font-weight: 400; */
    width: 100%;
    margin-top: 1.8em;
  }
  
  .nameanim::before {
    content: "<h1>";
    font-family: "La Belle Aurore",'SF Mono', cursive;
    color:#8891B0;
    font-size: 18px;
    position: absolute;
    margin-top: -43px;
    /* left: -15px; */
    left: 3%;
  }



  .smallText{
      text-align: justify;
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-size: 1.15em;
    color: #8891B0;
  }

 
  @media (max-width: 936px ){
    .smallText{

        font-size: 90% !important;
    }
    
  }

  @media (max-width: 936px ) and (min-width:616px ){
    .smallText{

        font-size: 125% !important;
    }
    
  }



  .smallTextLink{
      color:#64FFDA ;
     
  }

  .smallTextLink:hover{
    color:#64FFDA !important;
    text-decoration: underline !important;
}


  .nameanim::after{

    content: '<h1/>';
    font-family: 'La Belle Aurore','SF Mono', cursive;
    color: #8891B0;
    font-size: 18px;
    position: absolute;
    margin-top: 18px;
    margin-left: 20px;

  }
  
  .buttonD {
    /* padding: 15px 60px;
    background: #0079ff;
    color: #fff;
    border-radius: 5px;  
    text-decoration: none;*/
    cursor: pointer;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    transition: all 0.3s ease-out;
  }
  
  .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
  }
  
  .buttonD:hover {
    -webkit-animation-name: rubberBand;
            animation-name: rubberBand;
    display: inline-block;
    color: #64FFDA;
  }
  
  @-webkit-keyframes rubberBand {
    from {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes rubberBand {
    from {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  
  .angry-animate {
    -webkit-animation: bounce-in 1s ease-in 0s 1 normal;
    animation: bounce-in 1s ease-in 0s 1 normal;
    display: inline-block;
  }
  
  @-webkit-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    70% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
              transform: scale(0.3);
    }
    50% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    70% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  /* Not implemented
  animation-delay: 2s
  */
  
  .bounceIn {
    display: inline-block;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
  }
  
  @-webkit-keyframes bounceIn {
    0%,
    100%,
    20%,
    40%,
    60%,
    80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes bounceIn {
    0%,
    100%,
    20%,
    40%,
    60%,
    80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
/* $line-weight: 1px;
$circle-weight: 2px; */


.container1{
  width: 500px;
    height:500px;
  position: absolute;
  top:50%;
    left:50%;
  -webkit-transform: translate (-50%, -50%);
          transform: translate (-50%, -50%)
}


.kz-photo {
  width: 227px;
  height: 227px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 935px) { 
    
  

    .kz-photo{
        top:-38% !important;
    }

 }

.img {
  position: absolute;
  
  left: -1px; 
 
  /* border-radius: 50%;
  top: 4px;
  margin-top: 16px;
  left: 5px;
  */
  height: 159px;
  width: 163px;
  border-radius: 100%;
  overflow: hidden;
  border: 3px solid #64FFDA;

  z-index: 3;
  opacity: 1;
  -webkit-animation: photo-ani 4s forwards;
          animation: photo-ani 4s forwards;
}
.kz-photo-line {
  z-index: 2;
  position: relative;
}
#collar-line-right,
#collar-line,
#line-head { 
  fill: none;  
  stroke: #fff;
  stroke-width: 1px;

  stroke-dasharray: 1700;
  stroke-dashoffset: 1700;
  -webkit-animation: line-ani 3.5s forwards;
          animation: line-ani 3.5s forwards;
}
#circle {
  stroke-width: 3px;
  stroke: #fff;

  stroke-dasharray: 3900;
  stroke-dashoffset: 3900;
  -webkit-animation: line-ani 3s forwards;
          animation: line-ani 3s forwards;
}

/* Keyframes */
@-webkit-keyframes line-ani {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes line-ani {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes photo-ani {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes photo-ani {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} 
 
/* Style Demo */
/* body {
  background: #2c3e50;
} */


/* @media (min-width: 480px) and (max-width: 935px) { 
    
    .bigColHome{
        font-size: xx-large !important;
        line-height: 40px !important;
    }

 } */

 @media (max-width: 768px) { 
    
    .bigColHome{
        order: 2 !important;
    }

    .smallColHome{
        margin: 11em 0 0 15% !important;
    }

 }

/* 370 to 400 */

@media (max-width: 400px) { 
    
    .nameMedia::before{
        font-size: 18px !important;
        margin-top: -8% !important;
    }
    .nameMedia{
        font-size: smaller !important;
        margin-top: 26% !important;
       
    }
  .pageMedia{
      bottom: 0 !important;
      font-size: smaller !important;

    }

    .tableRow{
        font-size: smaller !important;
    }

    .ant-menu-root.ant-menu-inline {
      
    width: 80% !important;
    }

    
 }

@media (min-width: 545px) and (max-width: 935px){
    .rowSkillMedia{
        font-size: smaller !important;
    }
}

@media (min-width: 370px) and (max-width: 400px){
    .rowSkillMedia{
        font-size: smaller !important;
    }
}

@media(max-width:800px){
    .tableRow{
        display: none !important;
    }
}

@media (max-width:478px){
    .nameanim{
        
        margin-top:20% !important;
    }
}

 @media (min-width: 545px) and (max-width: 935px) { 
    
    .nameanim{
        font-size: xx-large !important;
        line-height: 40px !important;
        margin:30% 0 0% 8% !important;
    }

    .nameanim::before{
        left: 2% !important;
    }
 }


.dev-icons{
    /* padding-left: 0;
    list-style: none;
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 30%;
    text-align: center;
 */

    padding-left: 0;
    list-style: none;
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 61%;
    text-align: justify;
    /* margin-right: 2%; */
    padding: 0.4em;
    padding-left: 13%;

}

.software-skill-inline{
    display: inline-block;
    margin-right: 20px;
    line-height: 20px;
    color: #64FFDA;
    /* color: #4d4d4e !important; */
}

.software-skill-inline :hover{
   -webkit-transform: scale(1.06) !important;
           transform: scale(1.06) !important;
    /* color: #64FFDA; */
    color: #CCD6F6 !important;
}


.inner_smalText::before{
    content: "▹";
    position: absolute;
    left: 0.7em;
    color: rgb(100, 255, 218);
    font-size: 1em;
    line-height: 1.4em;
}

.inner_smalText{
    line-height: 1.4em;
    font-size: 1em;
    color: #8891B0;
    list-style:none;
    padding-left: 1.2em;
}


/*

<--- For Skill Table  ---->
*/



.ant-menu-inline  {
    border-right: 1px solid #CCD6F6  !important;
}
.ant-menu{
  background: transparent !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: rgb(100, 255, 218) !important;
  background: rgb(23, 42, 69) !important;
}

.ant-menu-inline .ant-menu-item::after {
   
    border-right: 2px solid #64FFDA  !important;
}

.ant-menu-inline .ant-menu-item{
   
    color: #8892B0 !important;
}

.ant-menu-inline .ant-menu-item:hover{
   
    color: #64FFDA !important;
}


.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}

@media(max-width: 1000px){
    .imgRowMedia{
        font-size: x-small!important;
    }

    .describeInner{
        margin-top: -12% !important;
        font-size: 55% !important;
    }
    .smallTextMedia, .titleFolioMedia{
        font-size: 50% !important;
    }
}


.titleFolioMedia{
   font-size: smaller;
   text-align:start;
  
}


repo-card-div:hover {
    box-shadow: 0 20px 30px -10px rgba(0,0,0,.2);
}

.repo-card-div {
    color: #586069;
    background-color: #fff;
    box-shadow: 0 10px 30px -15px rgba(0,0,0,.2);
    padding: 2rem;
    cursor: pointer;
}

.gridMain{
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
    gap: 10px;
}


.mainImg{
    grid-column: 4/ -1;
    grid-row: 1/ -1;
    position:relative;
    height: 100%;
  
    
    }

  
  .mainImg::before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-color: rgba(100, 255, 219, 0.529);
    z-index:3;
    transition:background-color 3s ease 0s;
  }
  .mainImg:hover::before  {
   background: transparent;
   content: none;
   
  }

  .mainImg:hover{
    -webkit-transform: scale(1.11);
            transform: scale(1.11);
  
  }


 .coverImg{
    border-radius: 0.2em;
    
} 

.img-links {
   grid-column: 2;
}
 .img-links1 {
    grid-column: 3;
 }
 .img-links2{
    grid-column: 4;
 
 }
 .img-links1:hover svg, .img-links:hover svg, .img-links2:hover svg    {
    display: none;
  }
 
  .img-links:hover::after   {
    content: "FE"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }

 .img-links1:hover::after   {
    content: "BE"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }

  .img-links2:hover::after   {
    content: "URL"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }




.link-txt{
    grid-column: 6/-1;
}



.describeImg{
    grid-row: 1;
grid-column: 1/5;
z-index: 5;
margin-top: 40%;
/* padding: 13%; */
text-align: center;
}



.describeInner{
    text-align: left;
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 5;
    background-color: rgb(23, 42, 69);
    color: #CCD6F6;
    
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
   padding: 1px 3% 0 3%;
    /* padding: 25px; */
    border-radius: 3px;
}

.describeInner:hover{
    box-shadow: rgba(2, 12, 27, 0.7) 0px 20px 30px -15px;
    position: relative;
    z-index: 2;
    background-color: rgb(23, 42, 69);
    color: rgb(168, 178, 209);
    /* font-size: 18px; */
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    /* padding: 25px; */
    border-radius: 3px;
}

