@import url("https://code.iconify.design/1/1.0.6/iconify.min.js");


@media (min-width: 545px) and (max-width: 935px){
    .rowSkillMedia{
        font-size: smaller !important;
    }
}

@media (min-width: 370px) and (max-width: 400px){
    .rowSkillMedia{
        font-size: smaller !important;
    }
}

@media(max-width:800px){
    .tableRow{
        display: none !important;
    }
}

@media (max-width:478px){
    .nameanim{
        
        margin-top:20% !important;
    }
}

 @media (min-width: 545px) and (max-width: 935px) { 
    
    .nameanim{
        font-size: xx-large !important;
        line-height: 40px !important;
        margin:30% 0 0% 8% !important;
    }

    .nameanim::before{
        left: 2% !important;
    }
 }


.dev-icons{
    /* padding-left: 0;
    list-style: none;
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 30%;
    text-align: center;
 */

    padding-left: 0;
    list-style: none;
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 61%;
    text-align: justify;
    /* margin-right: 2%; */
    padding: 0.4em;
    padding-left: 13%;

}

.software-skill-inline{
    display: inline-block;
    margin-right: 20px;
    line-height: 20px;
    color: #64FFDA;
    /* color: #4d4d4e !important; */
}

.software-skill-inline :hover{
   transform: scale(1.06) !important;
    /* color: #64FFDA; */
    color: #CCD6F6 !important;
}


.inner_smalText::before{
    content: "▹";
    position: absolute;
    left: 0.7em;
    color: rgb(100, 255, 218);
    font-size: 1em;
    line-height: 1.4em;
}

.inner_smalText{
    line-height: 1.4em;
    font-size: 1em;
    color: #8891B0;
    list-style:none;
    padding-left: 1.2em;
}


/*

<--- For Skill Table  ---->
*/



.ant-menu-inline  {
    border-right: 1px solid #CCD6F6  !important;
}
.ant-menu{
  background: transparent !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: rgb(100, 255, 218) !important;
  background: rgb(23, 42, 69) !important;
}

.ant-menu-inline .ant-menu-item::after {
   
    border-right: 2px solid #64FFDA  !important;
}

.ant-menu-inline .ant-menu-item{
   
    color: #8892B0 !important;
}

.ant-menu-inline .ant-menu-item:hover{
   
    color: #64FFDA !important;
}


.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}