
@media(max-width: 1000px){
    .imgRowMedia{
        font-size: x-small!important;
    }

    .describeInner{
        margin-top: -12% !important;
        font-size: 55% !important;
    }
    .smallTextMedia, .titleFolioMedia{
        font-size: 50% !important;
    }
}


.titleFolioMedia{
   font-size: smaller;
   text-align:start;
  
}


repo-card-div:hover {
    box-shadow: 0 20px 30px -10px rgba(0,0,0,.2);
}

.repo-card-div {
    color: #586069;
    background-color: #fff;
    box-shadow: 0 10px 30px -15px rgba(0,0,0,.2);
    padding: 2rem;
    cursor: pointer;
}

.gridMain{
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
}


.mainImg{
    grid-column: 4/ -1;
    grid-row: 1/ -1;
    position:relative;
    height: 100%;
  
    
    }

  
  .mainImg::before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-color: rgba(100, 255, 219, 0.529);
    z-index:3;
    transition:background-color 3s ease 0s;
  }
  .mainImg:hover::before  {
   background: transparent;
   content: none;
   
  }

  .mainImg:hover{
    transform: scale(1.11);
  
  }


 .coverImg{
    border-radius: 0.2em;
    
} 

.img-links {
   grid-column: 2;
}
 .img-links1 {
    grid-column: 3;
 }
 .img-links2{
    grid-column: 4;
 
 }
 .img-links1:hover svg, .img-links:hover svg, .img-links2:hover svg    {
    display: none;
  }
 
  .img-links:hover::after   {
    content: "FE"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }

 .img-links1:hover::after   {
    content: "BE"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }

  .img-links2:hover::after   {
    content: "URL"; 
    transition: color 0.3s ease-out 0s;
    color: #64FFDA!important;
    font-size: 0.5em;
    letter-spacing: 2px;
    text-align: center;
  }




.link-txt{
    grid-column: 6/-1;
}



.describeImg{
    grid-row: 1;
grid-column: 1/5;
z-index: 5;
margin-top: 40%;
/* padding: 13%; */
text-align: center;
}



.describeInner{
    text-align: left;
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 5;
    background-color: rgb(23, 42, 69);
    color: #CCD6F6;
    
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
   padding: 1px 3% 0 3%;
    /* padding: 25px; */
    border-radius: 3px;
}

.describeInner:hover{
    box-shadow: rgba(2, 12, 27, 0.7) 0px 20px 30px -15px;
    position: relative;
    z-index: 2;
    background-color: rgb(23, 42, 69);
    color: rgb(168, 178, 209);
    /* font-size: 18px; */
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    /* padding: 25px; */
    border-radius: 3px;
}
