
.mainstyle{
    background-image: linear-gradient(to right bottom, #337a85, #2b5d62, #224242, #182827, #090f0e);
  }
  
  .sidenavStyle{
    /* background: rgb(10, 14, 14); */
    /* background: #181818; */
    /* background: #172A45; */
    background: #01050C;
    
  }
  
  @media (min-width: 600px){
    .sideNavCol {
      
      max-width: 5.3em !important;
  }
}
  


@media (max-width: 450px){
  .sideNavCol {
    
    min-width: 5.3em !important;
}
}

.home-icon{
  font-family: 'icomoon' !important;
  /* speak: none; */
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.home-icon::before{
  content:"\e904"
}


  
  /* Nav styles #272727 */
  .navbutton{
    width: 95%;
    transition-duration: 0.5s;
    transition: transform 0.2s;
    /* border: 1px solid blue; */
    background: #1d2227;
    border: 1px solid white;
    border-radius: 8em;
    color: white;
    /* transition: 0.3s; */
    
  
  }
  .navbutton:hover{
    background: white;
    border: 1px solid #1d2227;
    transform: scale(1.07);
    color: #1d2227;
  
  }
  
  .topNavStyle{
    color: rgb(177, 177, 177) !important;
    text-transform: uppercase;
    font-size: x-small;
    font-weight: bolder;
  }
  

  
   .navlistStyle{
    line-height: 3.5em;
    text-align: center;
    display:block;
    cursor: pointer;
  } 
  
  .navlinkStyle{
    color: #4d4d4e !important;
    /* padding-left: 0.7em !important; */
    transition: color 0.5s ease-out 0s;
    display:block;
    font-size: 1.6em;
  }

  /* .lnr {
    color: #4d4d4e !important;
    font-size: 1.6em;
  } */
  /* 
    .lnr:hover{
      opacity: 0;
    } */


   /* .navlinkStyle:active    {
    color: #64FFDA!important;
   
    /* transform: scale(1.07); }
   
    .navlistStyle:hover a i   {
      display: none;
      /* transform: scale(1.07);} */
     
      .navlistStyle:hover a i   {
        display: none;
      }
     
    .navlistStyle:hover a::after   {
      
      transition: color 0.3s ease-out 0s;
      color: #64FFDA!important;
      font-size: 0.5em;
      letter-spacing: 2px;
      text-align: center;
    }


     .sideBarDiv  > li:nth-child(1):hover > a::after {
      content: "Home";
    }
    .sideBarDiv  > li:nth-child(2):hover > a::after {
      content: "Skills";
    }

    .sideBarDiv  > li:nth-child(3):hover > a::after {
      content: "Portfolio";
    }

    .sideBarDiv  > li:nth-child(4):hover > a::after {
      content: "Contact";
    }
  
  /* .navlinkStyle:hover i {
    display: none;
    /* transform: scale(1.07); */
    /*
  } 
 
 

  .navlinkStyle1:hover span  {
    display: block;
    /* transform: scale(1.07); */
   /*
  }

  .navlinkStyle b{
    display: none  !important;
  }

  */

  /* .navlinkStyle1::after{
    content: "portfolio";
    opacity: 1;

  } */
 

   /* .navlinkStyle:hover, .lnr::after{
    opacity: 1;
    content: 'Home';
   } */







  .chosen {
    color: #64FFDA!important;
    transition: color 0.5s ease-out 0s;
    display:block;
  }

  /* .navlinkStyle:hover,.navlinkStyle:active {
    color: #64FFDA!important;
    transform: scale(1.07);
    border-left: .10em solid  green !important;
    padding-left: 0.7em !important;
  } */
   
  /* .chosen {
    color: #64FFDA!important;
    border-left: .10em solid  green !important;
    
  
  } */

  


  
  /* .navlinkStyle:hover::after{
    color: white !important;
    transform: scale(1.07);
    content: ""!important;
    display: block!important;
    margin: 0 auto!important;
    padding-left: 0.5em!important;
    width: 75%!important;
    border-left: .10em solid  green !important;
  } */
  
  .topNavStyle:hover{
    color: white !important;
    transform: scale(1.07);
  }
  
  .topNavStyle:hover:after{
   
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 0.5em;
    width: 55%;
    border-bottom: .11em solid  rgb(0, 255, 0) !important;
   
  }
  
  
  /* #alertlist li.selected:after, #alertlist li:hover:after */
  .ajbjbjwbhbj:after{
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid rgb(47, 165, 53); /* This creates the border. Replace black with whatever color you want. */
  }
  
  
  
  
  a, a:hover{
    text-decoration: none !important;
  }
  
  .iconStyle{
    font-size: xx-large;
  }
  
  /* // Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) { 
    .navbutton{
      display:none
  
    }
  
    .iconStyle{
      font-size: xx-small !important;
    }
  }
  
  /* // Small devices (landscape phones, 576px and up) */
   @media (min-width: 576px) and (max-width: 767.98px) {
    .iconStyle{
      font-size: xx-small !important;
    } 
  } 