
/* 370 to 400 */

@media (max-width: 400px) { 
    
    .nameMedia::before{
        font-size: 18px !important;
        margin-top: -8% !important;
    }
    .nameMedia{
        font-size: smaller !important;
        margin-top: 26% !important;
       
    }
  .pageMedia{
      bottom: 0 !important;
      font-size: smaller !important;

    }

    .tableRow{
        font-size: smaller !important;
    }

    .ant-menu-root.ant-menu-inline {
      
    width: 80% !important;
    }

    
 }
