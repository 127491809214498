/* $line-weight: 1px;
$circle-weight: 2px; */


.container1{
  width: 500px;
    height:500px;
  position: absolute;
  top:50%;
    left:50%;
  transform: translate (-50%, -50%)
}


.kz-photo {
  width: 227px;
  height: 227px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (min-width: 935px) { 
    
  

    .kz-photo{
        top:-38% !important;
    }

 }

.img {
  position: absolute;
  
  left: -1px; 
 
  /* border-radius: 50%;
  top: 4px;
  margin-top: 16px;
  left: 5px;
  */
  height: 159px;
  width: 163px;
  border-radius: 100%;
  overflow: hidden;
  border: 3px solid #64FFDA;

  z-index: 3;
  opacity: 1;
  animation: photo-ani 4s forwards;
}
.kz-photo-line {
  z-index: 2;
  position: relative;
}
#collar-line-right,
#collar-line,
#line-head { 
  fill: none;  
  stroke: #fff;
  stroke-width: 1px;

  stroke-dasharray: 1700;
  stroke-dashoffset: 1700;
  animation: line-ani 3.5s forwards;
}
#circle {
  stroke-width: 3px;
  stroke: #fff;

  stroke-dasharray: 3900;
  stroke-dashoffset: 3900;
  animation: line-ani 3s forwards;
}

/* Keyframes */
@keyframes line-ani {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes photo-ani {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} 
 
/* Style Demo */
/* body {
  background: #2c3e50;
} */
