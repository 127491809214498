@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://cdn.linearicons.com/free/1.0.0/icon-font.min.css");
@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&amp;display=swap");

@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Homemade+Apple&display=swap');


@font-face {
  font-family: 'Calibre';
  src: url("./fonts/Calibre/Calibre-Regular.woff2") format('woff2'), url("./fonts/Calibre/Calibre-Regular.woff") format('woff'),
    url("./fonts/Calibre/Calibre-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Mono';
  src: url("./fonts/SFMono/SFMono-Medium.woff2") format('woff2'), url("./fonts/SFMono/SFMono-Regular.woff") format('woff'),
    url("./fonts/SFMono/SFMono-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}



.Calibre{
  font-family: 'Calibre', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'Segoe UI', Arial, 'sans-serif' !important;
}


.SFMono{
      font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', ' Lucida' ,'Console', 'Monaco', 'monospace' !important;
  }

body {
  margin: 0;
  font-family: 'Calibre', 'Nunito' , -apple-system, BlinkMacSystemFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:fuchsia;
  background: #0A192F !important;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tagUp{
  font-family:  'Cedarville Cursive','Homemade Apple',  "La Belle Aurore", 'SF Mono', cursive ;
    color:#8891B0;
    font-size: 15px;
    position: absolute;
    margin-top: -2em;
    left: -15px;
}

.tagDown{
  font-family:'Cedarville Cursive','Homemade Apple', "La Belle Aurore", 'SF Mono', cursive ;
    color:#8891B0;
    font-size: 15px;
    position: absolute;
    margin-top: 5%;
    left: -15px;
}

.sideSocial{
  /* width: 40px; */
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 6%;
    z-index: 10000;
    color: rgb(168, 178, 209);
}

.socialContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 0.2em;
}

.socialContainer::after{
  content: "";
  display: block;
  width: 0.06em;
  height: 8em;
  background-color: rgb(168, 178, 209);
  /* margin: 0px auto; */
  margin:0.6em 0 0 0;
}

a.socialLinks{
  color: #CCD6F6!important;
  /* padding-left: 0.7em !important; */
  transition: color 0.3s ease-out 0s;
  display:block;
  font-size: 1.5em;
  /* margin-bottom: 0.35em; */
  /* color: #64FFDA!important; */
  line-height: 2.1em;
}

a.socialLinks:hover{

   color: #64FFDA!important;
   transform: scale(1.12);
}

.myButton{
  font-family:  "Nunito", "SF Mono";
  width: 9.5em !important;
  /* height: auto; */
  border-color: #64FFDA !important;
  color: #64FFDA !important;
  height: 3em !important;
  background: transparent !important;

}
.myButton:hover{
  transform: scale(1.06);
 
  /* height: auto; */
  /* border-color: #63FCD8 !important;
  color: #64FFDA !important;
  height: 3em !important; */
  background: rgba(100, 255, 219, 0.105) !important;

}

@media (min-width: 370px) and (max-width: 935px) { 
    
  .mainDiv{
      margin: 20% 8% 0 3% !important;
  }

}

@media (max-width: 769px){
  .tagUp{
    margin-top: 4% !important;
  }
}

@media (min-width: 769px ){
  .tagUp{
    margin-top: 1% !important;
    left: -1% !important;
  }
}


.mainDiv{
  margin: 3em 0 3em 3em;
}

@media(max-width: 600px){
  .sideSocial{
    left: -80% !important;
  }

  .socialContainer {
   
    margin-bottom: -9% !important;
  }
}

