
/* @media (min-width: 480px) and (max-width: 935px) { 
    
    .bigColHome{
        font-size: xx-large !important;
        line-height: 40px !important;
    }

 } */

 @media (max-width: 768px) { 
    
    .bigColHome{
        order: 2 !important;
    }

    .smallColHome{
        margin: 11em 0 0 15% !important;
    }

 }